import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonFooter } from '@ionic/react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { addClient, getClient, updateClient } from '../actions/client';
import ClientContainer from '../components/ClientContainer';
import { RootState } from '../reducers';
import { Client, Agency } from '../types';

interface ParamTypes {
  client_id: string
}

const ClientPage: React.FC = () => {

  const dispatch = useDispatch();

  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const agency = useSelector<RootState, Agency>(state => state.dsr.dsrAgency);
  const client = useSelector<RootState, Client>(state => state.client.client);

  const { client_id } = useParams<ParamTypes>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (client_id) {
      dispatch(getClient(client_id));
    }
    // eslint-disable-next-line
  }, []);

  const edit = client_id ? true : false;

  const isAgency = location.pathname.includes('agencies') ? true : false;

  const onSubmit = () => {
    if (client.client_name) {
      if (edit) {
        dispatch(updateClient(client, history));
      } else {
        client.user_id = user_id;
        if (isAgency) {
          client.agency_ids = [agency.agency_id];
        }
        dispatch(addClient(client, history));
      }
    } else {
      dispatch(setAlert('All fields are required', 'danger'));
    }
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>{edit ? 'Edit' : 'New'} Client</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{edit ? 'Edit' : 'New'} Client</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ClientContainer agency={isAgency} />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>{edit ? 'Update' : 'Proceed'}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default ClientPage;
