import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonFooter } from '@ionic/react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { addAgency, getAgency, updateAgency } from '../actions/agency';
import AgencyContainer from '../components/AgencyContainer';
import { Agency } from '../types';
import { RootState } from '../reducers';

interface ParamTypes {
  agency_id: string
}

const AgencyPage: React.FC = () => {

  const dispatch = useDispatch()
  const agency = useSelector<RootState, Agency>(state => state.agency.agency);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const { agency_id } = useParams<ParamTypes>();
  const history = useHistory();

  useEffect(() => {
    if (agency_id) {
      dispatch(getAgency(agency_id));
    }    // eslint-disable-next-line
  }, []);

  const edit = agency_id ? true : false;

  const onSubmit = () => {
    if (agency.agency_name) {
      if (edit) {
        dispatch(updateAgency(agency, history));
      } else {
        agency.user_id = user_id;
        dispatch(addAgency(agency, history));
      }
    } else {
      dispatch(setAlert('All fields are required', 'danger'));
    }
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>{edit ? 'Edit' : 'New'} Agency</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{edit ? 'Edit' : 'New'} Agency</IonTitle>
          </IonToolbar>
        </IonHeader>
        <AgencyContainer />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>{edit ? 'Update' : 'Proceed'}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default AgencyPage;
