import React from 'react';
import { IonItem, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonGrid, IonRow, IonCol, IonText, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { Lead } from '../types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClosedLeads } from '../actions/lead';
import { RootState } from '../reducers';

interface ContainerProps {
  leads: Lead[],
  searchText: string
}

const ClosedLeads: React.FC<ContainerProps> = ({ leads, searchText }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const loadMore = useSelector<RootState, boolean>(state => state.lead.loadMoreOpenLeads);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);

  const getMoreLeads = ($event: CustomEvent<void>) => {
    dispatch(getClosedLeads(user_id, leads.length));
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  return (
    <>
      <IonGrid>
        {leads.map(lead => (
          <IonRow key={lead.id}>
            <IonCol>
              <IonCard onClick={() => {
                history.push(`/leads/${lead.id}`);
              }}>
                <IonCardHeader>
                  <IonCardTitle>{lead.company}</IonCardTitle>
                  <IonCardSubtitle>{lead.name}</IonCardSubtitle>
                  <IonCardSubtitle>{lead.city}</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol size-sm='2' size-xs='4'>
              <IonItem>
                <IonLabel className='ion-text-wrap'><IonText><h1>{lead.lead_deal.value}</h1></IonText><h2>Deal Value</h2></IonLabel>
              </IonItem>

            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
      <IonInfiniteScroll threshold="100px" disabled={searchText.length > 0 || !loadMore}
        onIonInfinite={(e: CustomEvent<void>) => getMoreLeads(e)}>
        <IonInfiniteScrollContent
          loadingText="Loading more leads...">
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default ClosedLeads;

