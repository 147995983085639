import React from 'react';
import { IonItem, IonLabel, IonList, IonText, IonCard } from '@ionic/react';
import Moment from 'react-moment';

interface ContainerProps {
  dsr: any
}

const DsrDetailContainer: React.FC<ContainerProps> = ({ dsr }) => (
  <IonCard>
    <IonList>
      {dsr.tagged_users.length > 0 && (
        <IonItem>
          <IonLabel className='ion-text-wrap'>
            <IonText>
              <h3>Tagged users</h3>
            </IonText>
            <IonText color='primary'>
              <h2>{dsr.tagged_users.map((user: any) => user.name).join(', ')}</h2>
            </IonText>
          </IonLabel>
        </IonItem>
      )}
      <IonItem>
        <IonLabel className='ion-text-wrap'>
          <IonText><h3>Meeting Type</h3></IonText>
          <IonText color='primary'>
            <h2>{dsr.meeting_type}</h2>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className='ion-text-wrap'>
          <IonText><h3>Organization Name</h3></IonText>
          <IonText color='primary'>
            <h2>{dsr.client ? dsr.client.name : dsr.agency.name}</h2>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className='ion-text-wrap'>
          <IonText><h3>Submitter</h3></IonText>
          <IonText color='primary'>
            <h2>{dsr.name}</h2>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <IonText><h3>Contact Details</h3></IonText>
          {dsr.contacts.map((contact: any) => (
            <IonText color="primary" key={contact.id}>
              <h2>{contact.contact_name} - {contact.designation}</h2>
            </IonText>
          ))}
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel className='ion-text-wrap'>
          <IonText><h3>Meeting Summary</h3></IonText>
          <IonText color="primary">
            <h2>{dsr.meeting_summary}</h2>
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Brand-wise Information Summary</IonLabel>
      </IonItem>
      {dsr.brands.map((brand: any) => (
        <IonCard key={brand.id}>
          <IonItem className='ion-text-center'>
            <IonLabel>{brand.client_name} | {brand.brand_name}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><IonText><h3>Key Markets</h3></IonText></IonLabel>
            <IonLabel>
              <IonText color="primary">
                <h2>{brand.key_market}</h2>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText><h3>Key Market Definition</h3></IonText></IonLabel>
            <IonLabel>
              <IonText color="primary">
                <h2>{brand.market_definition}</h2>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText><h3>Genre Under Consideration</h3></IonText></IonLabel>
            <IonLabel className='ion-text-wrap'>
              <IonText color="primary">
                <h2>{brand.channels}</h2>
              </IonText>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-wrap">
              <IonText><h3>Next Campaign Planned On</h3></IonText></IonLabel>
            <IonLabel>
              <IonText color="primary">
                <h2><Moment format='DD/MM/YYYY'>{brand.campaign_date}</Moment></h2>
              </IonText>
            </IonLabel>
          </IonItem>
          {brand.areas_of_concerns.map((aoc: any) => (
            <IonCard key={aoc.areas_of_concern_id}>
              <IonItem >
                <IonLabel className="ion-text-wrap">
                  <IonText><h3>{aoc.channel_name} part of Campaign</h3></IonText></IonLabel>
                <IonLabel>
                  <IonText color="primary">
                    <h2>{aoc.part_of_campaign}</h2>
                  </IonText>
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  <IonText><h3>Other Info</h3></IonText></IonLabel>
                <IonLabel className='ion-text-wrap'>
                  <IonText color="primary">
                    <h2>{aoc.other_info}</h2>
                  </IonText>
                </IonLabel>
              </IonItem>
            </IonCard>
          ))}
        </IonCard>
      ))}
    </IonList>
  </IonCard>
);

export default DsrDetailContainer;