import React, { useEffect } from 'react';
import { IonList, IonItem, IonLabel, IonCheckbox, IonText, IonInput } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import { Genre, Market } from '../types';
import { setDealMarketAction, setDealChannelAction, setDealValueAction } from '../actions/lead';
import { getMarkets, getGenres } from '../actions/campaign';

const DealCapture: React.FC = () => {
  const dispatch = useDispatch();
  const genres = useSelector<RootState, Genre[]>(state => state.campaign.genres);
  const markets = useSelector<RootState, Market[]>(state => state.campaign.markets);
  useEffect(() => {
    if (markets.length < 1) {
      dispatch(getMarkets());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (genres.length < 1) {
      dispatch(getGenres());
    }
    // eslint-disable-next-line
  }, []);
  const value = useSelector<RootState, string>(select => select.lead.value);

  return (
    <>
      <IonItem lines='none'>
        <IonLabel><IonText color='primary'><h1>Deal Details</h1></IonText></IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>Markets</IonLabel>
      </IonItem>
      <IonList>
        {markets.map(market => (
          <IonItem lines='none' key={market.id}>
            <IonLabel>{market.name}</IonLabel>
            <IonCheckbox slot="start" onIonChange={e => dispatch(setDealMarketAction(e.detail.checked, market.name))} />
          </IonItem>
        ))}
      </IonList>
      <IonItem lines='none'>
        <IonLabel>Channels/Genres</IonLabel>
      </IonItem>
      <IonList>
        {genres.map(genre => (
          <IonItem lines='none' key={genre.id}>
            <IonLabel>{genre.name}</IonLabel>
            <IonCheckbox slot="start" onIonChange={e => dispatch(setDealChannelAction(e.detail.checked, genre.name))} />
          </IonItem>
        ))}
      </IonList>
      <IonItem>
        <IonLabel position="fixed">Deal Value<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="text" value={value}
          onIonChange={e => dispatch(setDealValueAction(e.detail.value!))}></IonInput>
      </IonItem>
    </>
  )
}

export default DealCapture