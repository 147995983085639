import { GET_OPEN_LEADS, GET_CLOSED_LEADS, GET_LOST_LEADS, LEADS_FAIL, GET_LEAD_DETAIL, LEAD_TIMELINE_CHANGE, LEAD_STATUS_UPDATE, DEAL_STAGE_UPDATE, SET_LEAD_STATUS, SET_DEAL_STAGE, ADD_CLOSED_COMMENT, SET_CLOSED_REASON, UPDATE_FUTURE_MEETING, SET_MEETING_TIME, MARK_AS_POTENTIAL, SET_POTENTIAL, SET_MEETING_CONTENT, VIEW_MEETINGS, ADD_MEETING, SET_DEAL_CHANNEL, SET_DEAL_MARKET, SET_DEAL_VALUE, ADD_LEAD_DEAL, SEARCH_LEAD, GET_OPEN_LEADS_COMPLETE, GET_CLOSED_LEADS_COMPLETE, GET_LOST_LEADS_COMPLETE } from "./types";
import { Lead, LeadMeeting, LeadDeal } from '../types';
import { AppThunk } from "../store";
import axios from 'axios';
import { History } from 'history';
import { API_ENDPOINT } from "../constants";
import { setAlert } from "./alert";
import moment from 'moment';

interface OpenLeadAction {
  type: typeof GET_OPEN_LEADS,
  leads: Lead[],
  offset: number
}

interface ClosedLeadAction {
  type: typeof GET_CLOSED_LEADS,
  leads: Lead[],
  offset: number
}

interface LostLeadAction {
  type: typeof GET_LOST_LEADS,
  leads: Lead[],
  offset: number
}

interface LeadFailAction {
  type: typeof LEADS_FAIL,
  payload?: string
}

interface LeadDetailAction {
  type: typeof GET_LEAD_DETAIL,
  lead_id: number
}

interface TimelineUpdateAction {
  type: typeof LEAD_TIMELINE_CHANGE,
  lead: Lead
}

interface LeadStatusUpdateAction {
  type: typeof LEAD_STATUS_UPDATE,
  lead: Lead
}

interface DealStateUpdateAction {
  type: typeof DEAL_STAGE_UPDATE,
  lead: Lead
}

interface SetLeadStatusAction {
  type: typeof SET_LEAD_STATUS,
  status: string
}

interface SetDealStageAction {
  type: typeof SET_DEAL_STAGE,
  stage: string
}

interface AddClosedCommentAction {
  type: typeof ADD_CLOSED_COMMENT,
  comment: string
}

interface SetClosedReasonAction {
  type: typeof SET_CLOSED_REASON,
  checked: boolean,
  reason: string
}

interface FutureMeetingAction {
  type: typeof UPDATE_FUTURE_MEETING,
  lead: Lead
}

interface SetMeetingTimeAction {
  type: typeof SET_MEETING_TIME,
  meetingTime: string
}

interface SetPotentialAction {
  type: typeof SET_POTENTIAL,
  potential: boolean
}

interface MarkPotentialAction {
  type: typeof MARK_AS_POTENTIAL,
  lead: Lead
}

interface SetMeetingContentAction {
  type: typeof SET_MEETING_CONTENT,
  content: string
}

interface ViewMeetingAction {
  type: typeof VIEW_MEETINGS,
  meetings: LeadMeeting[]
}

interface AddMeetingAction {
  type: typeof ADD_MEETING,
  meeting: LeadMeeting
}

interface SetDealChannelAction {
  type: typeof SET_DEAL_CHANNEL,
  checked: boolean,
  channel: string
}

interface SetDealMarketAction {
  type: typeof SET_DEAL_MARKET,
  checked: boolean,
  market: string
}

interface SetDealValueAction {
  type: typeof SET_DEAL_VALUE,
  value: string
}

interface AddLeadDealAction {
  type: typeof ADD_LEAD_DEAL,
  deal: LeadDeal
}

interface SearchLeadAction {
  type: typeof SEARCH_LEAD,
  leadType: string,
  leads: Lead[]
}

interface GetOpenLeadsCompleteAction {
  type: typeof GET_OPEN_LEADS_COMPLETE
}

interface GetClosedLeadsCompleteAction {
  type: typeof GET_CLOSED_LEADS_COMPLETE
}

interface GetLostLeadsCompleteAction {
  type: typeof GET_LOST_LEADS_COMPLETE
}

export type LeadActionTypes = OpenLeadAction | ClosedLeadAction | LostLeadAction | 
  LeadFailAction | LeadDetailAction | TimelineUpdateAction | LeadStatusUpdateAction | 
  DealStateUpdateAction | SetLeadStatusAction | SetDealStageAction | AddClosedCommentAction |
  SetClosedReasonAction | FutureMeetingAction | SetMeetingTimeAction | MarkPotentialAction |
  SetPotentialAction | SetMeetingContentAction | ViewMeetingAction | AddMeetingAction |
  SetDealChannelAction | SetDealMarketAction | SetDealValueAction | AddLeadDealAction |
  SearchLeadAction | GetOpenLeadsCompleteAction | GetLostLeadsCompleteAction | GetClosedLeadsCompleteAction;

export const getOpenLeadsAction = (leads: Lead[], offset: number): LeadActionTypes => {
  return {
    type: GET_OPEN_LEADS,
    leads,
    offset
  }
}

export const getClosedLeadsAction = (leads: Lead[], offset: number): LeadActionTypes => {
  return {
    type: GET_CLOSED_LEADS,
    leads,
    offset
  }
}

export const getLostLeadsAction = (leads: Lead[], offset: number): LeadActionTypes => {
  return {
    type: GET_LOST_LEADS,
    leads,
    offset
  }
}

export const leadsFailAction = (payload?: string): LeadActionTypes => {
  return {
    type: LEADS_FAIL,
    payload
  }
}

export const getLeadAction = (lead_id: number): LeadActionTypes => {
  return {
    type: GET_LEAD_DETAIL,
    lead_id
  }
}

export const timelineUpdateAction = (lead: Lead): LeadActionTypes => {
  return {
    type: LEAD_TIMELINE_CHANGE,
    lead
  }
}

export const leadStatusUpdateAction = (lead: Lead): LeadActionTypes => {
  return {
    type: LEAD_STATUS_UPDATE,
    lead
  }
}

export const dealStageUpdateAction = (lead: Lead): LeadActionTypes => {
  return {
    type: DEAL_STAGE_UPDATE,
    lead
  }
}

export const setLeadStatusAction = (status: string): LeadActionTypes => {
  return {
    type: SET_LEAD_STATUS,
    status
  }
}

export const setDealStageAction = (stage: string): LeadActionTypes => {
  return {
    type: SET_DEAL_STAGE,
    stage
  }
}

export const addClosedCommentAction = (comment: string): LeadActionTypes => {
  return {
    type: ADD_CLOSED_COMMENT,
    comment
  }
}

export const setClosedReasonAction = (checked: boolean, reason: string): LeadActionTypes => {
  return {
    type: SET_CLOSED_REASON,
    checked,
    reason
  }
}

export const futureMeetingAction = (lead: Lead): LeadActionTypes => {
  return {
    type: UPDATE_FUTURE_MEETING,
    lead
  }
}

export const setMeetingTimeAction = (meetingTime: string): LeadActionTypes => {
  return {
    type: SET_MEETING_TIME,
    meetingTime: moment(meetingTime).format('YYYY-MM-DD HH:mm:00')
  }
}

export const setPotentialAction = (potential: boolean): LeadActionTypes => {
  return {
    type: SET_POTENTIAL,
    potential
  }
}

export const markPotentialAction = (lead: Lead): LeadActionTypes => {
  return {
    type: MARK_AS_POTENTIAL,
    lead
  }
}

export const setMeetingContentAction = (content: string): LeadActionTypes => {
  return {
    type: SET_MEETING_CONTENT,
    content
  }
}

export const viewMeetingAction = (meetings: LeadMeeting[]): LeadActionTypes => {
  return {
    type: VIEW_MEETINGS,
    meetings
  }
}

export const addMeetingAction = (meeting: LeadMeeting): LeadActionTypes => {
  return {
    type: ADD_MEETING,
    meeting
  }
}

export const setDealChannelAction = (checked: boolean, channel: string): LeadActionTypes => {
  return {
    type: SET_DEAL_CHANNEL,
    checked,
    channel
  }
}

export const setDealMarketAction = (checked: boolean, market: string): LeadActionTypes => {
  return {
    type: SET_DEAL_MARKET,
    checked,
    market
  }
}

export const setDealValueAction = (value: string): LeadActionTypes => {
  return {
    type: SET_DEAL_VALUE,
    value
  }
}

export const addLeadDealAction = (deal: LeadDeal): LeadActionTypes => {
  return {
    type: ADD_LEAD_DEAL,
    deal
  }
}

export const searchLeadAction = (leads: Lead[], leadType: string): LeadActionTypes => {
  return {
    type: SEARCH_LEAD,
    leadType,
    leads
  }
}

export const getOpenLeadsCompleteAction = (): LeadActionTypes => {
  return {
    type: GET_OPEN_LEADS_COMPLETE
  }
}

export const getClosedLeadsCompleteAction = (): LeadActionTypes => {
  return {
    type: GET_CLOSED_LEADS_COMPLETE
  }
}

export const getLostLeadsCompleteAction = (): LeadActionTypes => {
  return {
    type: GET_LOST_LEADS_COMPLETE
  }
}

export const searchLead = (user_id: string, search_term: string, leadType: string, closed = false, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/leads/search`, {
      params: { user_id, search_term, closed, offset }
    });
    if (res.data.status) {
      if (leadType === 'closed') {
        const leads = res.data.message.filter((item: Lead) => item.deal_stage === 'Deal Won')
        if (leads.length < 1) {
          dispatch(setAlert(`No closed leads matching ${search_term}`, 'danger'));
        } else {
          dispatch(searchLeadAction(leads, leadType));
        }
      } else if (leadType === 'lost') {
        const leads = res.data.message.filter((item: Lead) => item.lead_status === 'Lost Lead' || 
          item.lead_status === 'Junk Lead' || item.deal_stage === 'Deal Lost') 
        if (leads.length < 1) {
          dispatch(setAlert(`No lost leads matching ${search_term}`, 'danger'));
        } else {
          dispatch(searchLeadAction(leads, leadType));
        }
      } else {
        dispatch(searchLeadAction(res.data.message, leadType));
      }
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const addLeadDeal = (leadDeal: LeadDeal,  history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/add_deal`, leadDeal, config);
    if (res.data.status) {
      dispatch(addLeadDealAction(res.data.message));
      dispatch(setAlert('Lead deal added', 'success'));
      dispatch(updateDealStage(leadDeal.lead_id, 'Deal Won', history));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const addMeeting = (lead_id: number, meeting_summary: string, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id, meeting_summary };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/add_meeting_summary`, body, config);
    if (res.data.status) {
      dispatch(addMeetingAction(res.data.message));
      dispatch(setAlert('Meeting added', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const getMeetings = (lead_id: number): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/leads/meeting_summary`, {
      params: { lead_id }
    });
    if (res.data.status) {
      dispatch(viewMeetingAction(res.data.message));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};


export const markPotential = (lead_id: number, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/convert_to_potential`, body, config);
    if (res.data.status) {
      dispatch(markPotentialAction(res.data.message));
      dispatch(setAlert('Lead converted to potential', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const updateLeadStatus = (lead_id: number, lead_status: string, history: History,
  potential = false, comment? : string): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id, lead_status, comment };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/update_status`, body, config);
    if (res.data.status) {
      dispatch(leadStatusUpdateAction(res.data.message));
      dispatch(setAlert('Lead Status Updated', 'success'));
      if (potential) {
        dispatch(markPotential(lead_id, history));
      } else {
        history.goBack();
      }
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const updateDealStage = (lead_id: number, deal_stage: string, history: History, comment?: string): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id, deal_stage, comment };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/update_deal_stage`, body, config);
    if (res.data.status) {
      dispatch(dealStageUpdateAction(res.data.message));
      dispatch(setAlert('Deal Stage Updated', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const updateTimeline = (lead_id: number, timeline: string): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id, timeline };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/update_timeline`, body, config);
    if (res.data.status) {
      dispatch(timelineUpdateAction(res.data.message));
      dispatch(setAlert('Lead Timeline Updated', 'success'));
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const updateFutureMeeting = (lead_id: number, future_contact_time: string, history: History): AppThunk => async dispatch => {
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };
  const body = { lead_id, future_contact_time };
  try {
    const res = await axios.post(`${API_ENDPOINT}/leads/update_future_meeting`, body, config);
    if (res.data.status) {
      dispatch(futureMeetingAction(res.data.message));
      dispatch(setAlert('Lead Status Updated', 'success'));
      history.goBack();
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const getOpenLeads = (user_id: string, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/leads/open`, {
      params: { user_id, offset }
    });
    if (res.data.status) {
      dispatch(getOpenLeadsAction(res.data.message, offset));
    } else {
      dispatch(getOpenLeadsCompleteAction);
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const getClosedLeads = (user_id: string, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/leads/won`, {
      params: { user_id, offset }
    });
    if (res.data.status) {
      dispatch(getClosedLeadsAction(res.data.message, offset));
    } else {
      dispatch(getClosedLeadsCompleteAction);
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};

export const getLostLeads = (user_id: string, offset = 0): AppThunk => async dispatch => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/leads/lost`, {
      params: { user_id, offset }
    });
    if (res.data.status) {
      dispatch(getLostLeadsAction(res.data.message, offset));
    } else {
      dispatch(getLostLeadsCompleteAction);
      dispatch(setAlert(res.data.message, 'danger'));
      dispatch(leadsFailAction(res.data.message));
    }
  } catch (err) {
    dispatch(leadsFailAction(err.message));
  }
};