import React from "react"
import { IonRadioGroup, IonItem, IonLabel, IonRadio } from "@ionic/react"
import { setDealStageAction } from "../actions/lead"
import { DEAL_STAGE } from "../constants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../reducers"

const DealStage: React.FC = () => {

  const dealStage = useSelector<RootState, string>(state => state.lead.dealStage);
  const dispatch = useDispatch();

  return (
    <>
    <IonRadioGroup value={dealStage} onIonChange={
      e => dispatch(setDealStageAction(e.detail.value))
    }>
    {DEAL_STAGE.map(stage =>
      <IonItem lines='none' key={stage}>
        <IonLabel className='ion-text-wrap'>{stage}</IonLabel>
        <IonRadio mode='md' slot="start" value={stage} />
      </IonItem>)
    }
    </IonRadioGroup>
    </>
  )
}

export default DealStage