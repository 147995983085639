import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import ContactContainer from '../components/ContactContainer';
import { addContact, getContact, updateContact } from '../actions/contact';
import { setAlert } from '../actions/alert';
import { RESET_CONTACT } from '../actions/types';
import { RootState } from '../reducers';
import { Client, Agency, ClientContact, AgencyContact } from '../types';

interface ParamTypes {
  contact_id: string
}

const ContactPage: React.FC = () => {

  const { contact_id } = useParams<ParamTypes>();
  const location = useLocation();
  const history = useHistory();

  const [edit, setEdit] = useState(contact_id ? true : false);
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();

  const contact = useSelector<RootState, ClientContact | AgencyContact>(state => state.contact.contact);
  const client = useSelector<RootState, Client>(state => state.dsr.dsrClient);
  const agency = useSelector<RootState, Agency>(state => state.dsr.dsrAgency);

  useEffect(() => {
    if (contact_id) {
      dispatch(getContact(+contact_id, location.pathname.includes('agencies') ? true : false));
    }
    return function cleanup() {
      dispatch({ type: RESET_CONTACT });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!edit && ((contact as ClientContact).client_contact_id || (contact as AgencyContact).agency_contact_id)) {
      setShowAlert(true);
    }
  }, [dispatch, edit, contact]);

  const isAgency = location.pathname.includes('agencies') ? true : false;

  const onSubmit = () => {
    if (contact.title && contact.designation && (contact.first_name || contact.last_name) &&
      contact.email_id && contact.phone_number) {
      contact.contact_name = `${contact.title} ${contact.first_name} ${contact.last_name}`;
      const id = client.client_id ? client.client_id : agency.agency_id;
      if (edit) {
        dispatch(updateContact(contact, history, id, isAgency));
      } else {
        if (client.client_id) {
          (contact as ClientContact).client_id = id;
          dispatch(addContact(contact, history, isAgency));
        } else if (agency.agency_id) {
          (contact as AgencyContact).agency_id = id;
          dispatch(addContact(contact, history, isAgency));
        } else {
          dispatch(setAlert('You reached this the wrong way', 'danger'));
        }
      }
    } else {
      dispatch(setAlert('Please fill all required fields', 'danger'));
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../../home" />
          </IonButtons>
          <IonTitle>{edit ? 'Edit' : 'Add'} Contact</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{edit ? 'Edit' : 'Add'} Contact</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Contact already exists!'}
          message={`A contact with this email-id and/or phone number exists for ${contact.contact_name}. Would you like to update it and assign to this client/agency?`}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setShowAlert(false);
                console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'Ok',
              handler: () => {
                setShowAlert(false);
                if ((contact as ClientContact).client_contact_id) {
                  dispatch(getContact((contact as ClientContact).client_contact_id, false));
                } else {
                  dispatch(getContact((contact as AgencyContact).agency_contact_id, true));
                }
                setEdit(true);
              }
            }
          ]}
        />
        <ContactContainer edit={edit} isAgency={isAgency} />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>{edit ? 'Update' : 'Proceed'}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default ContactPage;
