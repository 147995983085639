export const SET_LOGIN_FORM_VALUE = 'SET_LOGIN_FORM_VALUE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const RESEND_PASSWORD = 'RESEND_PASSWORD';
export const RESEND_PASSWORD_FAIL = 'RESEND_PASSWORD_FAIL';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LIST_DSR = 'LIST_DSR';
export const LIST_DSR_COMPLETE = 'LIST_DSR_COMPLETE';
export const DSR_DETAIL = 'DSR_DETAIL';
export const LIST_DSR_FAIL = 'LIST_DSR_FAIL';
export const DSR_DETAIL_FAIL = 'DSR_DETAIL_FAIL';
export const SEARCH_DSR = 'SEARCH_DSR';
export const SEARCH_DSR_COMPLETE = 'SEARCH_DSR_COMPLETE';
export const SET_HOD = 'SET_HOD';
export const HOD_SUCCESS = 'HOD_SUCCESS';
export const HOD_FAIL = 'HOD_FAIL';
export const SEARCH_USERS_SUCCESS = 'TAGGED_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'TAGGED_USERS_FAIL';
export const SELECT_USER = 'SELECT_USER';
export const DESELECT_USER = 'DESELECT_USER';
export const SET_DSR_TAGGED_USERS = 'SET_DSR_TAGGED_USERS';
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const REMEMBER_RECENT_CLIENT = 'REMEMBER_RECENT_CLIENT';
export const UPDATE_RECENT_CLIENT = 'UPDATE_RECENT_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CLIENT_FAIL = 'CLIENT_FAIL';
export const SET_DSR_DATE = 'SET_DSR_DATE';
export const SET_DSR_CLIENT = 'SET_DSR_CLIENT';
export const SET_DSR_AGENCY = 'SET_DSR_AGENCY';
export const SET_DSR_AGENCY_CLIENTS = 'SET_DSR_AGENCY_CLIENTS';
export const SEARCH_AGENCY = 'SEARCH_AGENCY';
export const REMEMBER_RECENT_AGENCY = 'REMEMBER_RECENT_AGENCY';
export const UPDATE_RECENT_AGENCY = 'UPDATE_RECENT_AGENCY';
export const SET_AGENCY_NAME = 'SET_AGENCY_NAME';
export const ADD_AGENCY = 'ADD_AGENCY';
export const GET_AGENCY = 'GET_AGENCY';
export const UPDATE_AGENCY = 'UPDATE_AGENCY';
export const AGENCY_FAIL = 'AGENCY_FAIL';
export const GET_AGENCY_CLIENTS = 'GET_AGENCY_CLIENTS';
export const UPDATE_AGENCY_CLIENTS = 'UPDATE_AGENCY_CLIENTS';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';
export const SET_AGENCY_FOR_CLIENT = 'SET_AGENCY_FOR_CLIENT';
export const RESET_CONTACT = 'RESET_CONTACT';
export const GET_CONTACTS = 'GET_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const GET_CONTACT = 'GET_CONTACT';
export const UPDATE_CLIENT_CONTACT = 'UPDATE_CLIENT_CONTACT';
export const UPDATE_AGENCY_CONTACT = 'UPDATE_AGENCY_CONTACT';
export const ADD_CLIENT_CONTACT_FAIL = 'ADD_CLIENT_CONTACT_FAIL';
export const ADD_AGENCY_CONTACT_FAIL = 'ADD_AGENCY_CONTACT_FAIL';
export const CONTACT_FAIL = 'CONTACT_FAIL';
export const SET_DSR_CONTACT = 'SET_DSR_CONTACT';
export const SET_CONTACT_FORM_VALUE = 'SET_CONTACT_FORM_VALUE';
export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRAND_FOR_CAMPAIGN = 'GET_BRAND_FOR_CAMPAIGN';
export const GET_BRAND_FOR_EDIT = 'GET_BRAND_FOR_EDIT';
export const ADD_BRAND = 'ADD_BRAND';
export const SET_BRAND_CLIENT_ID = 'SET_BRAND_CLIENT_ID';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const BRAND_FAIL = 'BRAND_FAIL';
export const SET_BRAND_NAME = 'SET_BRAND_NAME';
export const SELECT_BRAND = 'SELECT_BRAND';
export const SET_TARGET_GROUP = 'SET_TARGET_GROUP';
export const GET_MARKETS = 'GET_MARKETS';
export const GET_GENRES = 'GET_GENRES';
export const SET_MEDIA_BRIEF = 'SET_MEDIA_BRIEF';
export const SET_KEY_MARKETS = 'SET_KEY_MARKETS';
export const SELECT_OTHER_MARKET = 'SELECT_OTHER_MARKET';
export const SET_KEY_OTHER_MARKET = 'SET_KEY_OTHER_MARKET';
export const SET_MARKET_DEFINITION = 'SET_MARKET_DEFINITION';
export const SET_CAMPAIGN_DATE = 'SET_CAMPAIGN_DATE';
export const SET_GENRES = 'SET_GENRES';
export const SET_CONCERNS = 'SET_CONCERNS';
export const SET_MEETING_TYPE = 'SET_MEETING_TYPE';
export const SET_MEETING_SUMMARY = 'SET_MEETING_SUMMARY';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN';
export const CREATE_DSR_SUMMARY = 'CREATE_DSR_SUMMARY';
export const ADD_DSR = 'ADD_DSR';
export const ADD_DSR_FAIL = 'ADD_DSR_FAIL';
export const SET_LOADING = 'SET_LOADING';
export const TG_UPDATE = 'TG_UPDATE';
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';
export const GET_OPEN_LEADS = 'GET_OPEN_LEADS';
export const GET_CLOSED_LEADS = 'GET_CLOSED_LEADS';
export const GET_LOST_LEADS = 'GET_LOST_LEADS';
export const SEARCH_LEADS = 'SEARCH_LEADS';
export const LEADS_FAIL = 'LEADS_FAIL';
export const GET_LEAD_DETAIL = 'GET_LEAD_DETAIL';
export const LEAD_TIMELINE_CHANGE = 'LEAD_TIMELINE_CHANGE';
export const LEAD_STATUS_UPDATE = 'LEAD_STATUS_UPDATE';
export const DEAL_STAGE_UPDATE = 'DEAL_STAGE_UPDATE';
export const SET_LEAD_STATUS = 'SET_LEAD_STATUS';
export const SET_DEAL_STAGE = 'SET_DEAL_STAGE';
export const ADD_CLOSED_COMMENT = 'ADD_CLOSED_COMMENT';
export const SET_CLOSED_REASON = 'SET_CLOSED_REASON';
export const UPDATE_FUTURE_MEETING = 'UPDATE_FUTURE_MEETING';
export const SET_MEETING_TIME = 'SET_MEETING_TIME';
export const SET_POTENTIAL = 'SET_POTENTIAL';
export const MARK_AS_POTENTIAL= 'MARK_AS_POTENTIAL';
export const SET_MEETING_CONTENT = 'SET_MEETING_CONTENT';
export const VIEW_MEETINGS = 'VIEW_MEETINGS';
export const ADD_MEETING = 'ADD_MEETING';
export const SET_DEAL_CHANNEL = 'SET_DEAL_CHANNEL';
export const SET_DEAL_MARKET = 'SET_DEAL_MARKET';
export const SET_DEAL_VALUE = 'SET_DEAL_VALUE';
export const ADD_LEAD_DEAL = 'ADD_LEAD_DEAL';
export const SEARCH_LEAD = 'SEARCH_LEAD';
export const GET_OPEN_LEADS_COMPLETE = 'GET_OPEN_LEADS_COMPLETE';
export const GET_CLOSED_LEADS_COMPLETE = 'GET_CLOSED_LEADS_COMPLETE';
export const GET_LOST_LEADS_COMPLETE = 'GET_LOST_LEADS_COMPLETE';