import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonItem, IonLabel, IonTextarea, IonCard, IonCardTitle, IonCardContent } from '@ionic/react';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addMeeting, getMeetings, setMeetingContentAction } from '../actions/lead';
import { RootState } from '../reducers';
import { LeadMeeting } from '../types';
import Moment from 'react-moment';

interface ParamTypes {
  lead_id: string
}

const LeadMeetingPage: React.FC = () => {
  const history = useHistory();
  const { lead_id } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const meetings = useSelector<RootState, LeadMeeting[]>(state => state.lead.meetings);
  const content = useSelector<RootState, string>(state => state.lead.content);

  useEffect(() => {
    if (lead_id) {
      dispatch(getMeetings(+lead_id));
    }
    // eslint-disable-next-line
  }, []);

  const onMeetingAdd = () => {
    dispatch(addMeeting(+lead_id, content, history));
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/leads" />
          </IonButtons>
          <IonTitle>Lead Meetings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Lead Meetings</IonTitle>
          </IonToolbar>
        </IonHeader>
        {
          meetings.length > 0 &&
          <IonItem>
            <IonLabel>Previous Meetings</IonLabel>
          </IonItem>
        }
        {
          meetings.map(meeting =>
            <IonCard key={meeting.id}>
              <IonCardTitle>
                <Moment format='DD/MM/YYYY'>{meeting.created_at}</Moment>
             </IonCardTitle>
              <IonCardContent>
                <IonItem >
                  <IonLabel className='ion-text-wrap'>{meeting.summary}</IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
          )
        }
        <IonItem>
          <IonLabel>Add Meeting Note</IonLabel>
        </IonItem>
        <IonItem>
          <IonTextarea name='content' value={content}
            placeholder='Meeting Note'
            onIonChange={e => dispatch(setMeetingContentAction(e.detail.value!))}></IonTextarea>
        </IonItem>
      </IonContent>
      {content && <IonFooter>
        <IonButton expand='block' onClick={() =>
          onMeetingAdd()
        }>Add</IonButton>
      </IonFooter>}
    </IonPage>
  );
};

export default LeadMeetingPage;
