import React, { useState } from 'react';
import { IonItem, IonLabel, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonRadioGroup, IonRadio, IonList, IonButton, IonText, IonDatetime, IonToggle } from '@ionic/react';
import { Lead } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { updateTimeline, setMeetingTimeAction, setPotentialAction } from '../actions/lead';
import { TIMELINE } from '../constants';
import { RootState } from '../reducers';
import moment from 'moment';
import { useHistory } from 'react-router';
import DealLostReason from './DealLostReason';
import LostLeadReason from './LostLeadReason';
import JunkLeadReason from './JunkLeadReason';
import LeadStatus from './LeadStatus';
import DealStage from './DealStage';
import EnquiryDetails from './EnquiryDetails';
import DealCapture from './DealCapture';

interface ContainerProps {
  lead: Lead
}

const LeadContainer: React.FC<ContainerProps> = ({ lead }) => {

  const history = useHistory();
  const minContactDate = moment().add(1, 'days').format('YYYY-MM-DD');
  const maxContactDate = moment().add(1, 'year').format('YYYY-MM-DD');

  const [showEnquiry, toggleEnquiry] = useState(false)
  const [showLeadStatus, toggleLeadStatus] = useState(false)
  
  const leadStatus = useSelector((state: RootState) => state.lead.leadStatus);
  const dealStage = useSelector((state: RootState) => state.lead.dealStage);
  const meetingTime = useSelector((state: RootState) => state.lead.meetingTime);
  const potential = useSelector((state: RootState) => state.lead.potential);

  const dispatch = useDispatch()

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{lead.company}</IonCardTitle>
        <IonCardSubtitle>{lead.name}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItem>
          <IonLabel>{lead.phone}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>{lead.email}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>{lead.deal_stage || lead.lead_status}</IonLabel>
        </IonItem>
        {!lead.closed && <IonItem>
          <IonButton fill='clear' onClick={() => {history.push(`/leads/${lead.id}/meetings`); }}>
            Meeting Summary
          </IonButton>
        </IonItem>}
        { lead.closed && lead.comment && (
        <IonItem>
          <IonLabel>Comments</IonLabel>
          <IonLabel className='ion-text-wrap'>{lead.comment.split('~~')[0]}</IonLabel>
        </IonItem>
        )}
        { lead.closed && lead.comment && lead.comment.split('~~').length > 1 && (
        <IonItem>
          <IonLabel>Reasons</IonLabel>
          <IonLabel className='ion-text-wrap'>{lead.comment.split('~~')[1].split(',').join(', ')}</IonLabel>
        </IonItem>
        )}
        {!lead.closed && <IonRadioGroup value={lead.timeline} onIonChange={
          e => dispatch(updateTimeline(lead.id, e.detail.value))
        }>
          {TIMELINE.map(timeline =>
            <IonItem lines='none' key={timeline}>
              <IonLabel>{timeline}</IonLabel>
              <IonRadio mode='md' slot="start" value={timeline} />
            </IonItem>)
          }
        </IonRadioGroup>}
        {!lead.closed && (
          <>
            <IonItem>
              <IonButton fill='clear' onClick={() => toggleEnquiry(!showEnquiry)}>
                Enquiry Details
              </IonButton>
            </IonItem>
            { showEnquiry && <EnquiryDetails lead={lead} />}
          </>
        )}
        {!lead.closed && !lead.potential && (
          <>
            <IonItem>
              <IonButton fill='clear' onClick={() => toggleLeadStatus(!showLeadStatus)}>
                Update Lead Status
              </IonButton>
            </IonItem>
            { showLeadStatus && <LeadStatus />}
          </>
        )}
        {!lead.closed && lead.potential && (
          <>
            <IonItem>
              <IonButton fill='clear' onClick={() => toggleLeadStatus(!showLeadStatus)}>
                Update Deal Stage
              </IonButton>
            </IonItem>
            { showLeadStatus && <DealStage/> }
          </>
        )}
        {leadStatus === 'Contacted' &&
          <IonItem hidden={lead.potential || !showLeadStatus}>
            <IonLabel><IonText color='primary'><h2>Mark as Potential</h2></IonText></IonLabel>
            <IonToggle checked={potential} onIonChange={e => dispatch(setPotentialAction(e.detail.checked))} />
          </IonItem>

        }
        {leadStatus === 'Contact in Future' && 
          <IonItem hidden={!showLeadStatus}> 
            <IonLabel><IonText color='primary'><h2>Future Contact Time</h2></IonText></IonLabel>
            <IonDatetime displayFormat="DD MMM YYYY HH:mm" min={minContactDate} value={meetingTime}
              max={maxContactDate} minuteValues="0, 15, 30, 45" onIonChange={e => dispatch(setMeetingTimeAction(e.detail.value!))}></IonDatetime>
          </IonItem>

        }
        {!lead.closed && leadStatus === 'Junk Lead' && <JunkLeadReason /> }
        {!lead.closed && leadStatus === 'Lost Lead' && <LostLeadReason /> }
        {!lead.closed && dealStage === 'Deal Lost' && <DealLostReason /> }
        {!lead.closed && dealStage === 'Deal Won' && <DealCapture /> }
        {lead.lead_deal && (
          <IonList>
            <IonItem>
              <IonLabel>Channels</IonLabel>
              <IonLabel className='ion-text-wrap'>{lead.lead_deal.channel.split(',').join(', ')}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Markets</IonLabel>
              <IonLabel className='ion-text-wrap'>{lead.lead_deal.market.split(',').join(', ')}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Deal Value</IonLabel>
              <IonLabel>{lead.lead_deal.value}</IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default LeadContainer;