import React from "react";
import { useDispatch } from "react-redux";
import { IonItem, IonLabel, IonText, IonTextarea, IonCheckbox } from "@ionic/react";
import {JUNK_LEAD_REASONS} from '../constants';
import { addClosedCommentAction, setClosedReasonAction } from '../actions/lead';

const JunkLeadReason: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <>
      <IonItem>
        <IonLabel><IonText color='primary'><h2>Junk Lead Reasons</h2></IonText></IonLabel>
      </IonItem>
      <IonItem>
        <IonTextarea placeholder='Comment' onIonChange={e => dispatch(addClosedCommentAction(e.detail.value!))} ></IonTextarea>
      </IonItem>
      {JUNK_LEAD_REASONS.map(reason =>
        <IonItem lines='none' key={reason}>
          <IonLabel className='ion-text-wrap'>{reason}</IonLabel>
          <IonCheckbox slot="start" onIonChange={e => dispatch(setClosedReasonAction(e.detail.checked, reason))} />
        </IonItem>
      )}
    </>
  )
}

export default JunkLeadReason