import React, { useState } from 'react'
import { IonItem, IonLabel, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonContent, IonRadioGroup, IonRadio, IonCheckbox, IonList, IonListHeader, IonFooter, IonTextarea, IonText } from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { setConcernsAction } from '../actions/campaign';
import { setAlert } from '../actions/alert';
import { CONCERNS, DEFAULT_OTHER_INFO, OTHERS } from '../constants';
import { CampaignGenre } from '../types';

interface ContainerProps {
  genre: CampaignGenre,
  onClose: any
}

const ConcernComponent: React.FC<ContainerProps> = ({ genre, onClose }) => {

  const dispatch = useDispatch();

  const [partOfCampaign, setPartOfCampaign] = useState<string>(genre.concern ? genre.concern.partOfCampaign : '');
  const [yesInfo, setYesInfo] = useState<string>(genre.concern && genre.concern.partOfCampaign === 'Yes' ? genre.concern.otherInfo[0] : '');
  const [othersChecked, checkOthers] = useState<boolean>(genre.concern && genre.concern.others ? true : false);
  const [others, setOthers] = useState<string | undefined>(genre.concern ? genre.concern.others : '');
  const [otherInfo, setOtherInfo] = useState<string[]>(genre.concern && genre.concern.partOfCampaign !== 'Yes' ? genre.concern.otherInfo : []);

  const onOtherInfo = (info: string, checked: boolean) => {
    if (checked) {
      setOtherInfo([...otherInfo, info]);
    } else {
      setOtherInfo(otherInfo.filter(item => item !== info));
    }
  }

  const onSubmit = () => {
    if (!partOfCampaign) {
      setAlert('Please provide a response', 'danger');
      return;
    }
    if (partOfCampaign === 'Yes') {
      dispatch(setConcernsAction(genre.id, { partOfCampaign, otherInfo: [yesInfo] }));
      onClose();
      return;
    }
    if (partOfCampaign !== 'Yes' && otherInfo.length < 1 && !others) {
      dispatch(setAlert('Please choose any other info', 'danger'));
      return;
    }
    if (othersChecked && !others) {
      dispatch(setAlert('Please provide other info or uncheck Others', 'danger'));
      return;
    }
    dispatch(setConcernsAction(genre.id, { partOfCampaign, otherInfo, others }));
    onClose();
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={(e) => onClose()}><IonIcon slot='start' icon={closeSharp}></IonIcon> Close</IonButton>
          </IonButtons>
          <IonTitle>Areas of Concern</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Areas of Concern</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem lines='none'>
          <IonLabel className='ion-text-wrap'>Is {genre.name} part of the campaign being discusssed?</IonLabel>
        </IonItem>
        <IonRadioGroup value={partOfCampaign} onIonChange={(e) => setPartOfCampaign(e.detail.value)}>
          {CONCERNS.map(concern =>
            <IonItem lines='none' key={concern}>
              <IonLabel>{concern}</IonLabel>
              <IonRadio mode='md' slot="start" value={concern} />
            </IonItem>)
          }
        </IonRadioGroup>
        {partOfCampaign && partOfCampaign !== 'Yes' &&
          <IonList>
            <IonListHeader>
              <IonLabel><IonText><h2>Any Other Information</h2></IonText></IonLabel>
            </IonListHeader>
            {DEFAULT_OTHER_INFO.map(item => (
              <IonItem lines='none' key={item}>
                <IonLabel>{item}</IonLabel>
                <IonCheckbox checked={otherInfo.indexOf(item) > -1} slot="start" onIonChange={e => onOtherInfo(item, e.detail.checked)} />
              </IonItem>
            ))}
            <IonItem lines='none'>
              <IonLabel>{OTHERS.name}</IonLabel>
              <IonCheckbox slot='start' checked={othersChecked} onIonChange={e => checkOthers(e.detail.checked)} />
            </IonItem>
            {othersChecked &&
              <IonItem>
                <IonTextarea name="others" value={others} placeholder={OTHERS.name}
                  onIonChange={e => setOthers(e.detail.value!)}></IonTextarea>
              </IonItem>}
            <IonLabel>&nbsp;</IonLabel>
          </IonList>
        }
        {partOfCampaign && partOfCampaign === 'Yes' &&
          <IonItem>
            <IonTextarea name='yesInfo' value={yesInfo}
              placeholder='Any Other Information'
              onIonChange={e => setYesInfo(e.detail.value!)}></IonTextarea>
          </IonItem>
        }
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>Save & Continue</IonButton>
      </IonFooter>
    </IonPage>
  )
}

export default ConcernComponent;