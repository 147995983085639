import React from "react"
import { IonRadioGroup, IonItem, IonLabel, IonRadio } from "@ionic/react"
import { setLeadStatusAction } from "../actions/lead"
import { LEAD_STATUS } from "../constants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../reducers"

const LeadStatus: React.FC = () => {

  const leadStatus = useSelector<RootState, string>(state => state.lead.leadStatus);
  const dispatch = useDispatch();

  return (
    <>
    <IonRadioGroup value={leadStatus} onIonChange={
      e => dispatch(setLeadStatusAction(e.detail.value))
    }>
    {LEAD_STATUS.map(status =>
      <IonItem lines='none' key={status}>
        <IonLabel className='ion-text-wrap'>{status}</IonLabel>
        <IonRadio mode='md' slot="start" value={status} />
      </IonItem>)
    }
    </IonRadioGroup>
    </>
  )
}

export default LeadStatus