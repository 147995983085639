import { Lead, LeadMeeting, LeadDeal } from '../types';
import { LeadActionTypes } from '../actions/lead';
import { GET_OPEN_LEADS, GET_CLOSED_LEADS, GET_LOST_LEADS, GET_LEAD_DETAIL, LEAD_TIMELINE_CHANGE, LEAD_STATUS_UPDATE, DEAL_STAGE_UPDATE, SET_LEAD_STATUS, SET_DEAL_STAGE, ADD_CLOSED_COMMENT, SET_CLOSED_REASON, UPDATE_FUTURE_MEETING, SET_MEETING_TIME, MARK_AS_POTENTIAL, SET_POTENTIAL, SET_MEETING_CONTENT, ADD_MEETING, VIEW_MEETINGS, SET_DEAL_CHANNEL, SET_DEAL_MARKET, SET_DEAL_VALUE, ADD_LEAD_DEAL, SEARCH_LEAD, GET_OPEN_LEADS_COMPLETE, GET_CLOSED_LEADS_COMPLETE, GET_LOST_LEADS_COMPLETE } from '../actions/types';

export interface LeadState {
  loading: boolean,
  openLeads: Lead[],
  lostLeads: Lead[],
  closedLeads: Lead[],
  lead: Lead,
  leadStatus: string,
  dealStage: string,
  comment: string,
  reasons: string[],
  meetingTime: string,
  potential: boolean,
  meetings: LeadMeeting[],
  content: string,
  channels: string[],
  markets: string[],
  value: string,
  deal: LeadDeal,
  loadMoreOpenLeads: boolean,
  loadMoreClosedLeads: boolean,
  loadMoreLostLeads: boolean,
}

const initialState: LeadState = {
  loading: true,
  openLeads: [],
  lostLeads: [],
  closedLeads: [],
  lead: {} as Lead,
  leadStatus: '',
  dealStage: '',
  comment: '',
  reasons: [],
  meetingTime: '',
  potential: false,
  meetings: [],
  content: '',
  channels: [],
  markets: [],
  value: '',
  deal: {} as LeadDeal,
  loadMoreOpenLeads: true,
  loadMoreClosedLeads: true,
  loadMoreLostLeads: true
}

export default function (state = initialState, action: LeadActionTypes) {
  switch (action.type) {
    case SEARCH_LEAD:
      if (action.leadType === 'open') {
        return { ...state, openLeads: action.leads }
      }
      if (action.leadType === 'closed') {
        return { ...state, closedLeads: action.leads }
      }
      return { ...state, lostLeads: action.leads }
    case ADD_LEAD_DEAL:
      return { ...state, deal: action.deal, channels: [], markets: [], value: '' }
    case SET_DEAL_CHANNEL:
      if (action.checked) {
        return { ...state, channels: [...state.channels, action.channel] }
      }
      return { ...state, channels: state.channels.filter(item => item !== action.channel) }
    case SET_DEAL_MARKET:
      if (action.checked) {
        return { ...state, markets: [...state.markets, action.market] }
      }
      return { ...state, markets: state.markets.filter(item => item !== action.market) }
    case SET_DEAL_VALUE:
      return { ...state, value: action.value }
    case SET_MEETING_CONTENT:
      return { ...state, content: action.content }
    case ADD_MEETING:
      return { ...state, meetings: [...state.meetings, action.meeting], content: '' }
    case VIEW_MEETINGS:
      return { ...state, meetings: action.meetings }
    case SET_POTENTIAL:
      return { ...state, potential: action.potential }
    case SET_MEETING_TIME:
      return { ...state, meetingTime: action.meetingTime }
    case ADD_CLOSED_COMMENT:
      return { ...state, comment: action.comment }
    case SET_CLOSED_REASON:
      if (action.checked) {
        return { ...state, reasons: [...state.reasons, action.reason] }
      }
      return { ...state, reasons: state.reasons.filter(item => item !== action.reason) };
    case SET_LEAD_STATUS:
      return { ...state, leadStatus: action.status, comment: '', reasons: [] }
    case SET_DEAL_STAGE:
      return { ...state, dealStage: action.stage, comment: '', reasons: [] }
    case LEAD_STATUS_UPDATE:
      if (action.lead.lead_status === 'Junk Lead' || action.lead.lead_status === 'Lost Lead') {
        return {
          ...state, openLeads: state.openLeads.filter(lead => lead.id !== action.lead.id),
          lostLeads: [...state.lostLeads, action.lead]
        }
      }
      return {
        ...state, lead: action.lead, openLeads: state.openLeads.map(lead =>
          lead.id === action.lead.id ? action.lead : lead)
      }
    case DEAL_STAGE_UPDATE:
      if (action.lead.deal_stage === 'Deal Lost') {
        return {
          ...state, openLeads: state.openLeads.filter(lead => lead.id !== action.lead.id),
          lostLeads: [...state.lostLeads, action.lead]
        }
      }
      if (action.lead.deal_stage === 'Deal Won') {
        const wonLead = action.lead
        wonLead.lead_deal = state.deal
        return {
          ...state, openLeads: state.openLeads.filter(lead => lead.id !== action.lead.id),
          closedLeads: [...state.closedLeads, wonLead]
        }
      }
      return {
        ...state, lead: action.lead, openLeads: state.openLeads.map(lead =>
          lead.id === action.lead.id ? action.lead : lead)
      }
    case MARK_AS_POTENTIAL:
    case LEAD_TIMELINE_CHANGE:
    case UPDATE_FUTURE_MEETING:
      return {
        ...state, lead: action.lead, openLeads: state.openLeads.map(lead =>
          lead.id === action.lead.id ? action.lead : lead)
      }
    case GET_OPEN_LEADS:
      if (action.offset === 0) {
        return { ...state, openLeads: action.leads, loading: false, loadMore: true }
      }
      return { ...state, openLeads: [...state.openLeads, ...action.leads] }
    case GET_OPEN_LEADS_COMPLETE:
      return { ...state, loadMoreOpenLeads: false }
    case GET_CLOSED_LEADS_COMPLETE:
      return { ...state, loadMoreClosedLeads: false }
    case GET_LOST_LEADS_COMPLETE:
      return { ...state, loadMoreLostLeads: false }
    case GET_CLOSED_LEADS:
      if (action.offset === 0) {
        return { ...state, closedLeads: action.leads, loading: false, loadMore: true }
      }
      return { ...state, closedLeads: [...state.closedLeads, ...action.leads]}
    case GET_LOST_LEADS:
      if (action.offset === 0) {
        return { ...state, lostLeads: action.leads, loading: false, loadMore: true }
      }
      return { ...state, lostLeads: [...state.lostLeads, ...action.leads], loading: false }
    case GET_LEAD_DETAIL:
      const leadItem = [...state.openLeads, ...state.closedLeads, ...state.lostLeads].find(item => item.id === action.lead_id);
      if (leadItem) {
        return {
          ...state, meetings: [], leadStatus: leadItem.lead_status, dealStage: leadItem.deal_stage,
          meetingTime: leadItem.future_contact_time, lead: leadItem, potential: leadItem.potential
        }
      }
      return state;
    default:
      return state;
  }
}