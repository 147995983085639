import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonSpinner } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { getBrands } from '../actions/brand';
import SelectBrandContainer from '../components/SelectBrandContainer';
import { createSummaryAction } from '../actions/dsr';
import { RootState } from '../reducers';
import { Brand, Campaign } from '../types';

interface ParamTypes {
  clients: string
}

const SelectBrand: React.FC = () => {

  const dispatch = useDispatch();
  const brands = useSelector<RootState, Brand[]>(state => state.brand.brands);
  const campaigns = useSelector<RootState, Campaign[]>(state => state.campaign.campaigns);
  const loading = useSelector<RootState, boolean>(state => state.brand.loading);
  const meetingSummary = useSelector<RootState, string>(state => state.dsr.meetingSummary);
  const meetingType = useSelector<RootState, string>(state => state.dsr.meetingType);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);

  const { clients } = useParams<ParamTypes>();
  const history = useHistory();

  useEffect(() => {
    if (clients) {
      dispatch(getBrands(clients));
    }
    // eslint-disable-next-line
  }, []);

  const validateAndProceed = () => {
    const selectedBrands = brands.filter(brand => brand.checked);
    if (selectedBrands.length < 1) {
      dispatch(setAlert('Please select at least one brand', 'danger'));
    } else if (!selectedBrands.map(brand => brand.brand_id).sort().every((item, index) => item === campaigns.map(campaign => campaign.brand_id).sort()[index])) {
      dispatch(setAlert('For each selected brand, please provide campaign details', 'danger'));
    } else if (!meetingSummary || !meetingType) {
      dispatch(setAlert('All fields are required', 'danger'));
    } else {
      dispatch(createSummaryAction(user_id, campaigns));
      history.push('/dsrs/summary');
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>Choose Brands</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Choose Brands</IonTitle>
          </IonToolbar>
        </IonHeader>
        {loading && brands === null ?
          (<IonSpinner />) :
          (<SelectBrandContainer brands={brands} />)
        }
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          validateAndProceed()
        }>Proceed</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default SelectBrand;
