import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonSegment, IonSegmentButton, IonLabel, IonButton, IonIcon, IonItem, IonSearchbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import OpenLeads from '../components/OpenLeads';
import ClosedLeads from '../components/ClosedLeads';
import LostLeads from '../components/LostLeads';
import { getClosedLeads, getLostLeads, getOpenLeads, searchLead } from '../actions/lead';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { setAlert } from '../actions/alert';
import { searchOutline } from 'ionicons/icons';
import { Lead } from '../types';

const LeadPage: React.FC = () => {

  const dispatch = useDispatch();
  const [leadType, setLeadType] = useState('open')
  const [searchText, setSearchText] = useState('');
  const user_id = useSelector<RootState, string>(state => state.user.user_id);
  const openLeads = useSelector<RootState, Lead[]>(state => state.lead.openLeads);
  const closedLeads = useSelector<RootState, Lead[]>(state => state.lead.closedLeads);
  const lostLeads = useSelector<RootState, Lead[]>(state => state.lead.lostLeads);

  useEffect(() => {
    dispatch(getOpenLeads(user_id))
    // eslint-disable-next-line
  }, []);
  
  const onSearchClicked = (e: any) => {
    e.preventDefault();
    if (searchText.length > 2) {
      searchLeadsByType();
    } else {
      dispatch(setAlert('Enter at least 3 characters', 'danger'));
    }
  };

  const searchLeadsByType = (type = leadType) => {
    if (type === 'open') {
      dispatch(searchLead(user_id, searchText, type, false));
    } else {
      dispatch(searchLead(user_id, searchText, type, true));
    }
  }

  const onSearchTextChange = (text: string) => {
    setSearchText(text);
    if (!text) {
      getLeadsByType();
    }
  }

  const getLeadsByType = (type = leadType) => {
    switch(type) {
      case 'open':
        dispatch(getOpenLeads(user_id))
        break
      case 'closed': 
        dispatch(getClosedLeads(user_id))
        break
      default:
        dispatch(getLostLeads(user_id))
        break
    }
  }

  const onCleared = () => {
    setSearchText('');
    getLeadsByType();
  }

  const onSegmentChange = (segment: string) => {
    setLeadType(segment);
    if (searchText.length > 2) {
      searchLeadsByType(segment)
    } else {
      getLeadsByType(segment)
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Lead Management</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Lead Management</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSegment value={leadType} color='primary' onIonChange={e => onSegmentChange(e.detail.value!)}>
          <IonSegmentButton value="open">
            <IonLabel>Open Leads</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="closed">
            <IonLabel>Closed Leads</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="lost">
            <IonLabel>Lost Leads</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonItem lines='none'>
        <IonSearchbar value={searchText} placeholder='Name/Company/City'
          onKeyDown={e => e.key === 'Enter' && onSearchClicked(e)}
          onIonChange={e => onSearchTextChange(e.detail.value!)}
          onIonCancel={() => onCleared()}
          onIonClear={() => onCleared()}></IonSearchbar>
        <IonButton fill='clear' onClick={e => onSearchClicked(e)}><IonIcon slot='icon-only' icon={searchOutline}></IonIcon></IonButton>
      </IonItem>
        { leadType === 'open' ? <OpenLeads leads={openLeads} searchText={searchText} /> : 
          leadType === 'closed' ? <ClosedLeads leads={closedLeads} searchText={searchText} /> : 
          <LostLeads leads={lostLeads} searchText={searchText}/> }
      </IonContent>
    </IonPage>
  );
};

export default LeadPage;
