import React, { useState } from 'react';
import { IonItem, IonList, IonSearchbar, IonLabel, IonCheckbox, IonButton, IonIcon, IonItemSliding, IonItemOptions, IonItemOption, IonRadioGroup, IonRadio, IonTextarea } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { personAdd, tvOutline, createOutline } from 'ionicons/icons';
import { MEETING_TYPES } from '../constants';
import { selectBrand } from '../actions/brand';
import { setMeetingTypeAction, setMeetingSummaryAction } from '../actions/dsr';
import { RootState } from '../reducers';

interface ContainerProps {
  brands: any
}

const SearchBrandContainer: React.FC<ContainerProps> = ({ brands }) => {

  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const meetingType = useSelector<RootState, string>(state => state.dsr.meetingType);
  const meetingSummary = useSelector<RootState, string>(state => state.dsr.meetingSummary);

  const history = useHistory();

  const onCampaignClicked = (brand: any) => {
    if (brand.target_group) {
      history.push(`/campaign/${brand.brand_id}`);
    } else {
      history.push(`/brands/tg/${brand.brand_id}`);
    }
  }

  return (
    <>
      <IonItem lines='none' className='ion-padding'>
        <IonSearchbar value={searchText} placeholder='Filter'
          onIonChange={e => setSearchText(e.detail.value!)}></IonSearchbar>
      </IonItem>
      <IonItem lines='none'>
        <IonButton fill='clear' onClick={() => history.push('/add-brands')}><IonIcon slot='start' icon={personAdd}></IonIcon>Add Brand</IonButton>
      </IonItem>
      <IonList>
        {brands.filter((brand: any) => searchText ? (brand.brand_name.toUpperCase().indexOf(searchText.toUpperCase()) > -1) : true).map((brand: any) => (
          <IonItemSliding key={brand.brand_id}>
            <IonItemOptions side="end">
              <IonItemOption onClick={() => history.push(`/brands/edit/${brand.brand_id}`)}>
                <IonIcon slot="start" icon={createOutline}></IonIcon>
              Edit</IonItemOption>
            </IonItemOptions>
            <IonItem lines='none' key={brand.brand_id}>
              <IonLabel class='ion-text-wrap'>{brand.brand_name}</IonLabel>
              <IonCheckbox slot="start" checked={brand.checked} onIonChange={e => dispatch(selectBrand(e.detail.checked, brand.brand_id))} />
              <IonButton fill='clear' slot='end' disabled={!brand.checked} onClick={() => onCampaignClicked(brand)}><IonIcon slot='icon-only' icon={tvOutline}></IonIcon></IonButton>
            </IonItem>
          </IonItemSliding>
        ))}
      </IonList>
      <IonItem lines='none'>
        <IonLabel>Meeting Type</IonLabel>
      </IonItem>
      <IonRadioGroup name='meeting_type' value={meetingType} onIonChange={e => dispatch(setMeetingTypeAction(e.detail.value))}>
        {MEETING_TYPES.map(meeting =>
          <IonItem lines='none' key={meeting}>
            <IonLabel>{meeting}</IonLabel>
            <IonRadio mode='md' slot="start" value={meeting} />
          </IonItem>)
        }
      </IonRadioGroup>
      <IonItem lines='none'>
        <IonLabel>Meeting Summary</IonLabel>
      </IonItem>
      <IonItem>
        <IonTextarea name='meeting_summary' value={meetingSummary} onIonChange={e => dispatch(setMeetingSummaryAction(e.detail.value!))}
          placeholder='Meeting summary'></IonTextarea>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </>
  );
};

export default SearchBrandContainer;