import React from 'react';
import { IonItem, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonGrid, IonRow, IonCol, IonText, IonLabel, IonCardContent, IonChip, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { Lead } from '../types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { getOpenLeads } from '../actions/lead';

interface ContainerProps {
  leads: Lead[],
  searchText: string
}

const OpenLeads: React.FC<ContainerProps> = ({ leads, searchText }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loadMore = useSelector<RootState, boolean>(state => state.lead.loadMoreOpenLeads);
  const user_id = useSelector<RootState, string>(state => state.user.user_id);

  const getMoreLeads = ($event: CustomEvent<void>) => {
    dispatch(getOpenLeads(user_id, leads.length));
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  return (
    <>
      <IonGrid>
        {leads.map(lead => (
          <IonRow key={lead.id}>
            <IonCol size-sm='10' size-xs='8'>
              <IonCard className='ion-no-padding' onClick={() => {
                history.push(`/leads/${lead.id}`);
              }}>
                <IonCardHeader>
                  <IonCardTitle>{lead.company}</IonCardTitle>
                  <IonCardSubtitle>{lead.name}</IonCardSubtitle>
                  <IonCardSubtitle>{lead.city}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className='ion-no-padding'>
                  {lead.potential ?
                    <IonChip color='primary' outline >
                      <IonLabel className='ion-text-wrap'>{lead.deal_stage || 'Potential'}</IonLabel>
                    </IonChip> :
                    <IonChip color='secondary' outline >
                      <IonLabel className='ion-text-wrap'>{lead.lead_status}</IonLabel>
                    </IonChip>
                  }
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size-sm='2' size-xs='4'>
              <IonItem>
                <IonLabel className='ion-text-wrap'><IonText><h1>{moment().diff(moment(lead.created_time), 'd')}</h1></IonText><h2>days</h2><h3>since lead creation</h3>  </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
      <IonInfiniteScroll threshold="100px" disabled={searchText.length > 0 || !loadMore}
        onIonInfinite={(e: CustomEvent<void>) => getMoreLeads(e)}>
        <IonInfiniteScrollContent
          loadingText="Loading more leads...">
        </IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};

export default OpenLeads;
