import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonFooter } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { addBrand, updateBrand, getBrandForEditAction } from '../actions/brand';
import BrandContainer from '../components/BrandContainer';
import { RootState } from '../reducers';
import { Client, Agency } from '../types';

interface ParamTypes {
  brand_id: string
}

const Brand: React.FC = () => {

  const dispatch = useDispatch();
  const brand = useSelector<RootState, any>(state => state.brand.brand);
  const dsrClient = useSelector<RootState, Client>(state => state.dsr.dsrClient);
  const dsrAgency = useSelector<RootState, Agency>(state => state.dsr.dsrAgency);

  const { brand_id } = useParams<ParamTypes>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (brand_id) {
      dispatch(getBrandForEditAction(+brand_id));
    }
    // eslint-disable-next-line
  }, []);

  const edit = brand_id ? true : false;

  const isAgency = dsrAgency && dsrAgency.agency_id ? true : false;

  const tgOnly = location.pathname.includes('tg') ? true : false;

  const onSubmit = () => {
    if (!brand.brand_name || !brand.target_group || (!tgOnly && isAgency && !brand.client_id)) {
      dispatch(setAlert('All fields are required', 'danger'));
      return;
    }
    if (edit) {
      dispatch(updateBrand(brand, history, tgOnly));
      return;
    }
    if (isAgency) {
      dispatch(addBrand(brand, history));
      return;
    }

    if (dsrClient && dsrClient.client_id) {
      brand.client_id = dsrClient.client_id;
      dispatch(addBrand(brand, history));
    } else {
      dispatch(setAlert('You reached this the wrong way', 'danger'));
    }
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>{edit ? 'Edit' : 'Add'} {tgOnly ? 'TG' : 'Brand'}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{edit ? 'Edit' : 'Add'} Brand</IonTitle>
          </IonToolbar>
        </IonHeader>
        <BrandContainer agency={isAgency} tgOnly={tgOnly} />
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={() =>
          onSubmit()
        }>{edit ? 'Update' : 'Proceed'} {tgOnly && 'TG'}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Brand;
