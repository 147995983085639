import React from "react"
import { IonList, IonItem, IonLabel } from "@ionic/react"
import { Lead } from '../types';

interface ContainerProps {
  lead: Lead
}

const EnquiryDetails: React.FC<ContainerProps> = ({lead}) => (
  <IonList>
    <IonItem>
      <IonLabel>Budget</IonLabel>
      <IonLabel>{lead.budget}</IonLabel>
    </IonItem>
    <IonItem>
      <IonLabel>TV Channels</IonLabel>
      <IonLabel className='ion-text-wrap'>{lead.tv_channels && lead.tv_channels.split(';').join(', ')}</IonLabel>
    </IonItem>
    {lead.media_vehicles_used && lead.media_vehicles_used !== 'null' && (
      <IonItem>
        <IonLabel className='ion-text-wrap'>Media Vehicles Used</IonLabel>
        <IonLabel>{lead.media_vehicles_used}</IonLabel>
      </IonItem>
    )}
    <IonItem>
      <IonLabel>Markets</IonLabel>
      <IonLabel className='ion-text-wrap'>{lead.markets}</IonLabel>
    </IonItem>
    {lead.tvc_check && lead.tvc_check !== 'null' && (
      <IonItem>
        <IonLabel>TVC Check</IonLabel>
        <IonLabel>{lead.tvc_check}</IonLabel>
      </IonItem>
    )}
  </IonList>
)

export default EnquiryDetails