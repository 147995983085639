import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter, IonButton, IonItem, IonLabel } from '@ionic/react';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadAction, updateLeadStatus, updateDealStage, updateFutureMeeting, addLeadDeal } from '../actions/lead';
import LeadContainer from '../components/LeadContainer';
import { RootState } from '../reducers';
import { Lead, LeadDeal } from '../types';
import { setAlert } from '../actions/alert';

interface ParamTypes {
  lead_id: string
}

const LeadDetailPage: React.FC = () => {
  const history = useHistory();
  const { lead_id } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const lead = useSelector<RootState, Lead>(state => state.lead.lead);
  const leadStatus = useSelector<RootState, string>(state => state.lead.leadStatus);
  const dealStage = useSelector<RootState, string>(state => state.lead.dealStage);
  const comment = useSelector<RootState, string>(state => state.lead.comment);
  const reasons = useSelector<RootState, string[]>(state => state.lead.reasons);
  const meetingTime = useSelector<RootState, string>(state => state.lead.meetingTime);
  const potential = useSelector<RootState, boolean>(state => state.lead.potential);
  const channels = useSelector<RootState, string[]>(state => state.lead.channels);
  const markets = useSelector<RootState, string[]>(state => state.lead.markets);
  const value = useSelector<RootState, string>(state => state.lead.value);

  useEffect(() => {
    if (lead_id) {
      dispatch(getLeadAction(+lead_id));
    }
    // eslint-disable-next-line
  }, []);

  const updateLead = () => {
    let closedComment;
    if (dealStage) {
      if (dealStage === 'Deal Lost') {
        if (comment || reasons.length > 0) {
          closedComment = `${comment}~~${reasons.join(', ')}`
          dispatch(updateDealStage(lead.id, dealStage, history, closedComment))
        } else {
          dispatch(setAlert('Comment or reason required', 'danger'));
        }
      } else if (dealStage === 'Deal Won') {
        if (markets.length > 0 && channels.length > 0 && value) {
          const leadDeal: LeadDeal = {
            lead_id: +lead_id,
            channel: channels.join(', '),
            market: markets.join(', '),
            value
          }
          dispatch(addLeadDeal(leadDeal, history))
        } else {
          dispatch(setAlert('Markets, Channels and Deal Value required', 'danger'));
        }
      } else {
        dispatch(updateDealStage(lead.id, dealStage, history))
      }
    } else if (leadStatus) {
      if (leadStatus === 'Junk Lead' || leadStatus === 'Lost Lead') {
        if (comment || reasons.length > 0) {
          closedComment = `${comment}~~${reasons.join(', ')}`
          dispatch(updateLeadStatus(lead.id, leadStatus, history, potential, closedComment))
        } else {
          dispatch(setAlert('Comment or reason required', 'danger'));
        }
      } else if (leadStatus === 'Contact in Future') {
        if (meetingTime) {
          dispatch(updateFutureMeeting(lead.id, meetingTime, history))
        } else {
          dispatch(setAlert('Future Contact Time required', 'danger'));
        }
      } else {
        dispatch(updateLeadStatus(lead.id, leadStatus, history, potential))
      }
    }
  }

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/leads" />
          </IonButtons>
          <IonTitle>Lead Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Lead Details</IonTitle>
          </IonToolbar>
        </IonHeader>
        {
          lead.name ? <LeadContainer lead={lead} /> :
            <IonItem>
              <IonLabel>Details for lead with id {lead_id} not available</IonLabel>
            </IonItem>
        }

      </IonContent>
      {(leadStatus !== lead.lead_status || dealStage !== lead.deal_stage ||
        meetingTime !== lead.future_contact_time ||
        potential !== lead.potential) && <IonFooter>
          <IonButton expand='block' onClick={() =>
            updateLead()
          }>Update</IonButton>
        </IonFooter>}
    </IonPage>
  );
};

export default LeadDetailPage;
