import { useDispatch } from "react-redux";
import React from "react";
import { IonItem, IonLabel, IonText, IonTextarea, IonCheckbox } from "@ionic/react";
import { addClosedCommentAction, setClosedReasonAction } from "../actions/lead";
import { LOST_LEAD_REASONS } from "../constants";

const LostLeadReason: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <>
      <IonItem>
        <IonLabel><IonText color='primary'><h2>Lost Lead Reasons</h2></IonText></IonLabel>
      </IonItem>
      <IonItem>
        <IonTextarea placeholder='Comment' onIonChange={e => dispatch(addClosedCommentAction(e.detail.value!))} ></IonTextarea>
      </IonItem>
      {LOST_LEAD_REASONS.map(reason =>
        <IonItem lines='none' key={reason}>
          <IonLabel className='ion-text-wrap'>{reason}</IonLabel>
          <IonCheckbox slot="start" onIonChange={e => dispatch(setClosedReasonAction(e.detail.checked, reason))} />
        </IonItem>
      )}
    </>
  )
}

export default LostLeadReason